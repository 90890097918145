import { render, staticRenderFns } from "./PropertiesGridFund.html?vue&type=template&id=63f17b7d&scoped=true&"
import script from "./PropertiesGridFund.ts?vue&type=script&lang=ts&"
export * from "./PropertiesGridFund.ts?vue&type=script&lang=ts&"
import style0 from "@/scss/components/properties/_properties.scss?vue&type=style&index=0&id=63f17b7d&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "63f17b7d",
  null
  
)

export default component.exports