import { Vue, Component } from 'vue-property-decorator';
import Logo from '@/components/common/logo/Logo.vue';
import TwitterIcon from '@/assets/icons/twitter.svg?inline';
// eslint-disable-next-line import/extensions
import LinkedInIcon from '@/assets/icons/linkedin.svg?inline';
import { email } from '../../../../whitelabel.config';

@Component({
  components: {
    Logo,
    TwitterIcon,
    LinkedInIcon,
  },
})

export default class FooterExpanded extends Vue {
  email = email;
}
